/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import moment from "moment"
import defaultImage from '../images/Preview.jpg'
function Seo({ description,
               lang,
               meta,
               title,
               keywords,
               publishedDate,
               updateDate
             }) {

  const { site, allStrapiOption } = useStaticQuery(query)
  const publishDate = publishedDate || site.siteMetadata.releaseDate;
  const updateTime = updateDate == null ? moment().format() : moment(updateDate).format();
  const metaDescription = description || site.siteMetadata.description;
  const metaTitle = title || site.siteMetadata.title;
  const metaKeywords = keywords || site.siteMetadata.keywords;
  const seoImage = allStrapiOption.nodes[0].seoImage.localFile.publicURL ||  defaultImage;
  const defaultTitle = "IntelBuilding"
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      titleTemplate={`%s | ${defaultTitle}`}
      meta={[
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          name: `image`,
          content: seoImage,
        },
        {
          property: `og:image`,
          content: seoImage,
        },
        {
          property: `twitter:image`,
          content: seoImage,
        },
        {
          property: `article:published_time`,
          content: publishDate,
        },
        {
          property: `article:modified_time`,
          content: updateTime,
        },
        {
          property: `og:site_name`,
          content: 'IntelBuilding',
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: "pages:keywords",
          content: metaKeywords,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(
          metaKeywords.length > 0
              ? {
                name: `keywords`,
                content: metaKeywords.join(`, `),
              }
              : []
      ).concat(meta)}
    />
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo


const query = graphql`
  query SEO {
    site {
      siteMetadata {
        releaseDate
        description
        title
        author
        keywords
      }
    }
    allStrapiOption {
      nodes {
        seoImage {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`
